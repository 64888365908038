// Generated by Framer (cd03546)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["B6IduLd0F"];

const variantClassNames = {B6IduLd0F: "framer-v-6expb4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "B6IduLd0F", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-aP1i1", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-6expb4", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"B6IduLd0F"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><SVG className={"framer-1dbp67u"} data-framer-name={"shape-arrow 12"} layout={"position"} layoutDependency={layoutDependency} layoutId={"zkMa_MKa3"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 32 32\"><path d=\"M 16.569 31.016 L 28.349 19.235 C 28.74 18.845 28.74 18.212 28.349 17.821 C 27.959 17.431 27.325 17.431 26.935 17.821 L 16.861 27.895 L 16.861 2.805 C 16.861 2.253 16.414 1.805 15.861 1.805 C 15.309 1.805 14.861 2.253 14.861 2.805 L 14.861 27.894 L 4.788 17.821 C 4.398 17.431 3.764 17.431 3.374 17.821 C 2.983 18.212 2.983 18.845 3.374 19.235 L 15.154 31.016 C 15.545 31.406 16.178 31.406 16.569 31.016 Z\" fill=\"rgb(33,33,33)\"></path></svg>"} svgContentId={3156021394} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-aP1i1 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-aP1i1 .framer-w2xbt1 { display: block; }", ".framer-aP1i1 .framer-6expb4 { height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-aP1i1 .framer-1dbp67u { flex: none; height: 32px; left: calc(50.00000000000002% - 32px / 2); position: absolute; top: calc(50.00000000000002% - 32px / 2); width: 32px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerxfBQV3i_g: React.ComponentType<Props> = withCSS(Component, css, "framer-aP1i1") as typeof Component;
export default FramerxfBQV3i_g;

FramerxfBQV3i_g.displayName = "shape-arrow 12";

FramerxfBQV3i_g.defaultProps = {height: 32, width: 32};

addFonts(FramerxfBQV3i_g, [])